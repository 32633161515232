// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-js": () => import("/opt/build/repo/src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-en-js": () => import("/opt/build/repo/src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-fr-js": () => import("/opt/build/repo/src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-js": () => import("/opt/build/repo/src/pages/it.js" /* webpackChunkName: "component---src-pages-it-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/PrivacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

